import React from 'react'
import clsx from 'clsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFacebookF,
  faInstagram,
  faYoutube,
  faTiktok
} from '@fortawesome/free-brands-svg-icons'
import i18n from '../locales/he.yaml'

const SocialButtons = ({ isColor = false, isLarge = false }) => (
  <span className='buttons items-center justify-center'>
    <a
      className={clsx(
        'button is-link-ka is-flex direction-column is-justify-content-flex-start',
        {
          'is-color': isColor,
          'is-medium': isLarge
        }
      )}
      href='https://www.facebook.com/karinnarts'
      target='_blank'
      rel='noreferrer'
    >
      <FontAwesomeIcon icon={faFacebookF} />
      <small>{i18n.social.facebook}</small>
    </a>
    <a
      className={clsx(
        'button is-link-ka is-flex direction-column is-justify-content-flex-start',
        {
          'is-color': isColor,
          'is-medium': isLarge
        }
      )}
      href='https://www.instagram.com/karinnarts'
      target='_blank'
      rel='noreferrer'
    >
      <FontAwesomeIcon icon={faInstagram} />
      <small>{i18n.social.instagram}</small>
    </a>
    <a
      className={clsx(
        'button is-link-ka is-flex direction-column is-justify-content-flex-start',
        {
          'is-color': isColor,
          'is-medium': isLarge
        }
      )}
      href='https://www.youtube.com/karinnarts'
      target='_blank'
      rel='noreferrer'
    >
      <FontAwesomeIcon icon={faYoutube} />
      <small>{i18n.social.youtube}</small>
    </a>
    <a
      className={clsx(
        'button is-link-ka is-flex direction-column is-justify-content-flex-start',
        {
          'is-color': isColor,
          'is-medium': isLarge
        }
      )}
      href='https://www.tiktok.com/@karinn.emanuel'
      target='_blank'
      rel='noreferrer'
    >
      <FontAwesomeIcon icon={faTiktok} />
      <small>{i18n.social.tiktok}</small>
    </a>
  </span>
)

export default SocialButtons
